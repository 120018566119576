import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import NotificationBar from "./NotificationBar";
import { authenticatedUser } from "../../helper/AuthHelper";
import { useHistory } from "react-router-dom";
import AddBankModal from "./AddBankModal";

const ProtectedRoute = ({
  path,
  loading,
  component: Component,
  render,
  user,
  getAccounts,
  ...rest
}) => {
  const [isBankModalVisible, setIsBankModalVisible] = useState(false);
  const [isBanksEmpty, setIsBanksEmpty] = useState(false);
  const [userData, setUserData] = useState(authenticatedUser());
  const [licenseUploaded, setlicenseUploaded] = useState(true);

  const openBankModal = () => setIsBankModalVisible(true);
  const closeBankModal = () => setIsBankModalVisible(false);

  const history = useHistory();

  const userType = userData?.user_type;

  const handleSuccess = () => {
    setIsBanksEmpty(false);
    closeBankModal();
  };

  const isLicenseComplete =
    userData?.drivers_license?.license_number &&
    userData?.drivers_license?.license_image &&
    userData?.drivers_license?.expiry_date
      ? true
      : false;

  const getBanks = async () => {
    await getAccounts()
      .then((res) => {
        const data = res.payload.data.banks;
        if (data?.length === 0) {
          setIsBanksEmpty(true);
        } else {
          setIsBanksEmpty(false);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    if (!isLicenseComplete) {
      setlicenseUploaded(false);
    } else {
      setlicenseUploaded(true);
    }
  }, [isLicenseComplete]);

  useEffect(() => {
    setUserData(authenticatedUser());
  }, [loading]);

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userData) return <Redirect to="/login" />;
        if (!userData?.is_email_or_phone_verified)
          return <Redirect to="/verify" />;

        return Component ? (
          <>
            {userType === "driver" && !licenseUploaded && (
              <NotificationBar
                text="You need to upload your Driver's License."
                buttonText="Click here to upload"
                onClick={() => history.push("/app/profile")}
              />
            )}

            {isBanksEmpty && (
              <NotificationBar
                text="Update your onboarding details to complete your setup and get moving"
                buttonText="Click here to Complete Onboarding"
                onClick={openBankModal}
              />
            )}

            <AddBankModal
              isModalVisible={isBankModalVisible}
              handleCancel={closeBankModal}
              handleSuccess={handleSuccess}
            />
            <Component {...props} />
          </>
        ) : (
          render(props)
        );
      }}
    />
  );
};

const mapStateToProps = ({ user, loading }) => ({ user, loading });

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
