import React from "react";
import styled from "styled-components";
import Icon, { ExclamationCircleOutlined } from "@ant-design/icons";

const NotificationBar = ({ text, buttonText, onClick }) => {
  return (
    <Container>
      <div className="inner">
        <span className="icon">
          <Icon component={ExclamationCircleOutlined} />
        </span>
        <p>
          {text} <span onClick={onClick}> {buttonText} </span>
        </p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e27626;
  border-bottom: 1px solid white;

  .inner {
    display: flex;
    align-items: end;
    gap: 6px;
  }

  .icon {
    color: white;
  }

  p,
  span {
    color: white;
    font-size: 14px;
  }
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default NotificationBar;
