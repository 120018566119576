import React, { useState, useEffect } from "react";
import Map, { Marker, Source, Layer, FullscreenControl } from "react-map-gl";
import MapboxDirectionsFactory from "@mapbox/mapbox-sdk/services/directions";
import PubNub from "pubnub";
import { lineString as makeLineString } from "@turf/helpers";
import "mapbox-gl/dist/mapbox-gl.css";
import { TRIP_STATE } from "../../constants/Enums";
import { envConfig } from "../../config";

let pubnub = null;

const GoMap = ({ data, height = "50", home, setTripStatus = () => {} }) => {
  const [viewPort, setViewPort] = useState({
    zoom: 14,
    latitude: 6.524379,
    longitude: 3.379206,
  });
  const [route, setRoute] = useState(null);
  const [truckLocation, setTruckLocation] = useState({});
  const [truckCoord, setTruckCoord] = useState([]);

  const { start_location, end_location, last_known_vehicle_location, state } =
    data;

  useEffect(() => {
    const newViewPort = { ...viewPort };
    // newViewPort.zoom = 15;

    if (
      last_known_vehicle_location?.coordinates?.length > 0 &&
      !truckLocation?.latitude
    ) {
      newViewPort.latitude = last_known_vehicle_location?.coordinates[1];
      newViewPort.longitude = last_known_vehicle_location?.coordinates[0];

      setViewPort(newViewPort);
    } else if (
      start_location?.coordinates.length > 0 &&
      !truckLocation?.latitude &&
      !last_known_vehicle_location?.coordinates?.length > 0
    ) {
      newViewPort.latitude = start_location?.coordinates[1];
      newViewPort.longitude = start_location?.coordinates[0];

      setViewPort(newViewPort);
    } else if (truckLocation?.latitude && truckLocation?.longitude) {
      newViewPort.latitude = truckLocation?.latitude;
      newViewPort.longitude = truckLocation?.longitude;

      setViewPort(newViewPort);
    }

    if (!home) {
      fetchRoute();
    }
  }, [data, truckLocation]);

  // const getDestination = (status) => {
  //   if (status === TRIP_STATE.ON_ROUTE) {
  //     return start_location?.coordinates;
  //   } else if (status === TRIP_STATE.ON_ROUTE_DROPOFF && data?.drop_off_location?.coordinates?.length > 0) {
  //     return data?.drop_off_location?.coordinates;
  //   } else {
  //     return end_location?.coordinates;
  //   }
  // };

  const accessToken = envConfig.REACT_APP_MAPBOX_TOKEN;
  const directionsClient = MapboxDirectionsFactory({ accessToken });
  const startingPoint = last_known_vehicle_location?.coordinates
    ? last_known_vehicle_location?.coordinates
    : start_location?.coordinates;
  const startLocation = start_location?.coordinates;
  const destinationPoint = end_location?.coordinates;
  const dropOffLocation = data?.drop_off_location?.coordinates;

  const fetchRoute = async () => {
    const starting = truckCoord?.length > 0 ? truckCoord : startingPoint;
    // const destination =  getDestination(truckLocation?.status)

    const currentStatus = truckLocation?.status
      ? truckLocation?.status
      : state?.value;

    const destination =
      currentStatus === TRIP_STATE.ON_ROUTE
        ? destinationPoint
        : currentStatus === TRIP_STATE.ON_ROUTE_DROPOFF
        ? dropOffLocation
        : destinationPoint;

    // if(truckCoord?.length > 0 && trackingId === truckLocation.trip_id){
    //     console.log(starting)
    //     console.log("please workkkkkk")
    // }
    const reqOptions = {
      waypoints: [{ coordinates: starting }, { coordinates: destination }],
      profile: "driving-traffic",
      geometries: "geojson",
    };
    const res = await directionsClient.getDirections(reqOptions).send();
    // console.log(res?.body?.routes[0]?.geometry?.coordinates);
    const newRoute = makeLineString(
      res?.body?.routes[0]?.geometry?.coordinates
    );
    // console.log(newRoute)
    setRoute(newRoute);
  };

  useEffect(() => {
    if (!home) {
      // console.log("Initiating")
      initPubNub();
      connectPubNub();
    }
  }, [data]);

  const initPubNub = () => {
    pubnub = new PubNub({
      subscribeKey: envConfig.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
      publishKey: envConfig.REACT_APP_PUBNUB_PUBLISH_KEY,
      uuid: process.env.REACT_APP_PUBNUB_UUID,
      ssl: true,
    });
  };

  const connectPubNub = () => {
    pubnub.subscribe({ channels: [data?._id] });
    pubnub.addListener({ message: handleMessage });
  };

  const handleMessage = (event) => {
    // console.log("handling message from pubnub");
    // console.log(event, "pubhub");
    try {
      setTruckLocation(event.message.data);
      setTripStatus(event.message.data.status);
      setTruckCoord([
        event.message.data?.longitude,
        event.message.data?.latitude,
      ]);
      // console.log(event.message.data.status, data.state.value)
    } catch (e) {
      console.log(e);
    }
    // return data;
  };

  return (
    <div>
      <Map
        {...viewPort}
        mapboxAccessToken={envConfig.REACT_APP_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        style={{
          width: "100%",
          height: `${height}vh`,
          margin: "0 auto 40px",
          overflow: "hidden",
        }}
        onDrag={(viewPort) => {
          setViewPort(viewPort.viewState);
        }}
        add
        attributionControl={false}
        onZoom={(viewPort) => {
          setViewPort(viewPort.viewState);
        }}
      >
        {data?.start_location?.coordinates?.length > 0 && (
          <Marker
            latitude={data?.start_location?.coordinates[1]}
            longitude={data?.start_location?.coordinates[0]}
          >
            <img
              src={"/starticon.png"}
              alt={"start-icon"}
              style={{ width: "70px", height: "70px" }}
            />
          </Marker>
        )}

        {data?.end_location?.coordinates?.length > 0 && (
          <Marker
            latitude={data?.end_location?.coordinates[1]}
            longitude={data?.end_location?.coordinates[0]}
          >
            <img
              src={"/stopicon.png"}
              alt={"stop-icon"}
              style={{ maxWidth: "25px", maxHeight: "25px" }}
            />
          </Marker>
        )}

        {data?.last_known_vehicle_location?.coordinates.length > 0 &&
          !truckLocation?.latitude && (
            <Marker
              latitude={data?.last_known_vehicle_location?.coordinates[1]}
              longitude={data?.last_known_vehicle_location?.coordinates[0]}
              rotation={data?.last_known_vehicle_location?.bearing}
              bearing={data?.last_known_vehicle_location?.bearing}
            >
              <img
                src={"/truck_map.png"}
                alt={"moving-icon"}
                style={{ width: "60px", height: "60px" }}
              />
            </Marker>
          )}

        {truckLocation?.latitude && truckLocation.longitude && (
          <Marker
            latitude={truckLocation?.latitude}
            longitude={truckLocation.longitude}
            rotation={truckLocation?.bearing}
            bearing={truckLocation?.bearing}
          >
            <img
              src={"/truck_map.png"}
              alt={"moving-icon"}
              style={{ width: "60px", height: "60px" }}
            />
          </Marker>
        )}

        {route && (
          <Source id="my-data" type="geojson" data={route}>
            <Layer
              id="lineLayer"
              type="line"
              source="my-data"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": "rgba(1,31,43,0.5)",
                "line-width": 4,
              }}
            />
          </Source>
        )}

        <FullscreenControl position="bottom-right" />
      </Map>
    </div>
  );
};

export default GoMap;
