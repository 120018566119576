import React, { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";
import AddBankModal from "../../components/common/AddBankModal";
import { toast } from "react-toastify";
import { BANKS } from "../../configs/common-options";
import BillingCard from "../../components/billing/BillingCard";
import { confirmAlert } from "react-confirm-alert";
import "../../styles/main.css";

const Billing = (props) => {
  const [data, setData] = useState(props.account_list);
  const [defaultBank, setDefaultBank] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingBanks, setLoadingBanks] = useState(false);
  const [radioChanged, setRadioChanged] = useState(false);

  const onDelete = async (id, e) => {
    e.preventDefault();

    await props
      .deleteBank(id)
      .then((res) => {
        // window.location.href = "/app/settings/billing";
        setRadioChanged((radioChanged) => !radioChanged);
      })
      .catch((error) => {
        toast.error("Error deleting bank!");
        return error;
      });
  };

  const handleDelete = (id, e) => {
    confirmAlert({
      title: "Delete Bank",
      message:
        "Are you sure you want to delete this bank account?.This action cannot be reversed.",
      buttons: [
        {
          label: "Yes",
          onClick: () => onDelete(id, e),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleRadio = async (bank) => {
    if (bank.account_number !== defaultBank) {
      await props
        .setActiveBank(bank._id)
        .then((res) => {
          setRadioChanged((radioChanged) => !radioChanged);
        })
        .catch((error) => {
          toast.error("Error");
          return error;
        });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getBanks = async () => {
    setLoadingBanks(true);
    await props
      .getAccounts()
      .then((res) => {
        const newData = [...res.payload.data.banks];
        const displayData = [];
        for (const bankData of newData) {
          for (const bankInfo of BANKS) {
            if (bankData.bank_name === bankInfo.value) {
              bankData.image = bankInfo.image;
            }
          }
          displayData.push(bankData);
        }
        setData(displayData);
        setLoadingBanks(false);
      })
      .catch((error) => {
        setLoadingBanks(false);
        toast.error("Error getting getting banks data!");
        return error;
      });
  };

  const handleSuccess = () => {
    getBanks();
  };

  useEffect(getBanks, [radioChanged]);

  const activeBank = data?.find((a) => a.active);
  const notActiveBanks = data?.filter((a) => !a.active);

  const sortedBanks = activeBank
    ? [activeBank, ...notActiveBanks]
    : [...notActiveBanks];

  return (
    <>
      <div className="billing">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #E4E7EC",
            paddingBottom: "5px",
            marginBottom: "20px",
          }}
        >
          <div>
            <h2 className="text-7xl text-primary">Billing</h2>
            <p style={{ color: "#667085", margin: 0 }}>
              You will receive payments in your Default account
            </p>
          </div>
          <Button
            className="billing-button all-button"
            type="primary"
            onClick={showModal}
          >
            Add new bank
          </Button>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {loadingBanks ? (
            <div className="billing-center">
              <Spin size="default" />
            </div>
          ) : (
            sortedBanks?.map((detail, i) => (
              <BillingCard
                detail={detail}
                key={i}
                defaultBank={defaultBank}
                setDefaultBank={setDefaultBank}
                onDelete={handleDelete}
                handleRadio={handleRadio}
              />
            ))
          )}
        </div>

        <AddBankModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleSuccess={handleSuccess}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ account_list, account_name }) => ({
  account_list,
  account_name,
});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
