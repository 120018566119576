import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Spin } from "antd";
import CustomSelect from "./Select";
import { BANKS } from "../../configs/common-options";
import loadingIcon from "../common/loadingIcon";
import "../../styles/main.css";
import { toast } from "react-toastify";
import axios from "axios";
import { envConfig } from "../../config";
import { connect } from "react-redux";
import { reduxFunctions } from "../../helper/ReduxHelper";

const AddBankModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [accountInfo, setAccountInfo] = useState({
    account_number: "",
    bank_name: "",
    bank_code: "",
    account_name: "",
  });

  const _startLoading = () => setLoading(true);
  const _stopLoading = () => setLoading(false);

  const handleInfoChange = ({ target: input }) => {
    const newData = { ...accountInfo };
    newData[input.name] = input.value;
    setAccountInfo(newData);
  };

  const clearAccountInfo = () => {
    setAccountInfo({ account_number: "", bank_name: "", bank_code: "" });
  };

  const handleOk = () => {
    props.handleCancel();
    clearAccountInfo();
  };

  const handleAdd = async () => {
    _startLoading();
    await props
      .createBank(accountInfo)
      .then(async (res) => {
        toast.success("Bank added successfully");
        _stopLoading();
        clearAccountInfo();
        props.handleSuccess && props.handleSuccess();
      })
      .catch((error) => {
        _stopLoading();
        clearAccountInfo();
        toast.error("Error adding bank!");
        return error;
      });
    props.handleCancel();
  };

  const getAccountname = async () => {
    if (accountInfo?.account_number.length === 10 && accountInfo?.bank_code) {
      setVerifying(true);
      await axios
        .get(
          `https://api.paystack.co/bank/resolve?account_number=${accountInfo?.account_number}&bank_code=${accountInfo?.bank_code}`,
          {
            headers: {
              Authorization: `Bearer ${envConfig.REACT_APP_PAYSTACK_KEY}`,
            },
          }
        )
        .then(async (res) => {
          setAccountInfo({
            ...accountInfo,
            account_name: res.data.data.account_name,
          });
          setVerifying(false);
        })
        .catch((error) => {
          setVerifying(false);
          toast.error(error.response.data.message || "Error validating bank");
        });
    }
  };

  const handleSelectChange = (value) => {
    BANKS.forEach((bank) => {
      if (bank.value === value) {
        const newAccountInfo = { ...accountInfo };
        newAccountInfo.bank_name = value;
        newAccountInfo.bank_code = bank.code;
        setAccountInfo(newAccountInfo);
      }
    });
  };

  useEffect(() => {
    getAccountname();
  }, [accountInfo?.account_number]);

  return (
    <div>
      <Modal
        title="Add New Bank"
        className="add-info-modal"
        visible={props.isModalVisible}
        onOk={handleOk}
        onCancel={props.handleCancel}
        footer={[
          <Button
            className="modal-button"
            key="back"
            onClick={props.handleCancel}
            size={"large"}
          >
            Cancel
          </Button>,
          <Button
            disabled={!accountInfo.account_name}
            key="submit"
            className="modal-button"
            type="primary"
            onClick={handleAdd}
            size={"large"}
          >
            {loading ? <Spin indicator={loadingIcon} /> : <span>Save</span>}
          </Button>,
        ]}
      >
        <Form layout="vertical" className="modal-form">
          <Form.Item label="Bank Name" name="bank_name" className="modal-label">
            <div className="selectContainer2">
              <CustomSelect
                options={BANKS}
                size="large"
                name="bank_name"
                handleChange={handleSelectChange}
                value={accountInfo.bank_name}
                bordered={false}
              />
            </div>
          </Form.Item>
          <Form.Item label="Account Number" className="modal-label">
            <Input
              className="modal-input"
              name="account_number"
              value={accountInfo.account_number}
              onChange={handleInfoChange}
            />
          </Form.Item>
          <p style={{ textAlign: "right" }}>
            {verifying ? "Validating..." : accountInfo?.account_name}
          </p>
          {/*<Form.Item*/}
          {/*    label="Bank code"*/}
          {/*    className='modal-label'*/}
          {/*>*/}
          {/*    <Input className='modal-input' name="bank_code" value={accountInfo.bank_code} onChange={handleInfoChange}/>*/}
          {/*</Form.Item>*/}
        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = reduxFunctions;

export default connect(mapStateToProps, mapDispatchToProps)(AddBankModal);
