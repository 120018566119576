import { envConfig } from "../../config";
import { TRIP_STATE } from "../../constants/Enums";
export const GET_TEST = "KAGO/Redux/GET_TEST";
export const GET_TEST_SUCCESS = "KAGO/Redux/GET_TEST_SUCCESS";
export const GET_TEST_FAIL = "KAGO/Redux/GET_TEST_FAIL";
// User

export const SET_DRIVER_LOCATION = "KAGO/Redux/SET_DRIVER_LOCATION";
export const SET_CURRENT_TRIP = "KAGO/Redux/SET_CURRENT_TRIP";

export const SET_TRIP_DETAIL = "KAGO/Redux/SET_TRIP_DETAIL";
export const LOGIN_USER = "KAGO/Redux/LOGIN_USER";
export const LOGIN_USER_SUCCESS = "KAGO/Redux/LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "KAGO/Redux/LOGIN_USER_FAIL";

export const LOGOUT_USER = "KAGO/Redux/LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "KAGO/Redux/LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "KAGO/Redux/LOGOUT_USER_FAIL";

export const RESET_PASSWORD = "KAGO/Redux/RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "KAGO/Redux/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "KAGO/Redux/RESET_PASSWORD_FAIL";

export const PASSWORD_RESET_EMAIL = "KAGO/Redux/PASSWORD_RESET_EMAIL";
export const PASSWORD_RESET_EMAIL_SUCCESS =
  "KAGO/Redux/PASSWORD_RESET_EMAIL_SUCCESS";
export const PASSWORD_RESET_EMAIL_FAIL = "KAGO/Redux/PASSWORD_RESET_EMAIL_FAIL";

export const SIGNUP_USER = "KAGO/Redux/SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "KAGO/Redux/SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAIL = "KAGO/Redux/SIGNUP_USER_FAIL";

export const SIGNUP_USER_FOR_COMPANY = "KAGO/Redux/SIGNUP_USER_FOR_COMPANY";
export const SIGNUP_USER_FOR_COMPANY_SUCCESS =
  "KAGO/Redux/SIGNUP_USER_FOR_COMPANY_SUCCESS";
export const SIGNUP_USER_FOR_COMPANY_FAIL =
  "KAGO/Redux/SIGNUP_USER_FOR_COMPANY_FAIL";

export const SIGNUP_COMPANY = "KAGO/Redux/SIGNUP_COMPANY";
export const SIGNUP_COMPANY_SUCCESS = "KAGO/Redux/SIGNUP_COMPANY_SUCCESS";
export const SIGNUP_COMPANY_FAIL = "KAGO/Redux/SIGNUP_COMPANY_FAIL";
//

export const GET_USER = "KAGO/Redux/GET_USER";
export const GET_USER_SUCCESS = "KAGO/Redux/GET_USER_SUCCESS";
export const GET_USER_FAIL = "KAGO/Redux/GET_USER_FAIL";

export const GET_USER_WITH_ID = "KAGO/Redux/GET_USER_WITH_ID";
export const GET_USER_WITH_ID_SUCCESS = "KAGO/Redux/GET_USER_WITH_ID_SUCCESS";
export const GET_USER_WITH_ID_FAILURE = "KAGO/Redux/GET_USER_WITH_ID_FAILURE";

export const UPDATE_USER = "KAGO/Redux/UPDATE_USER";
export const UPDATE_USER_SUCCESS = "KAGO/Redux/UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "KAGO/Redux/UPDATE_USER_FAIL";

export const GET_USER_COMPANY = "KAGO/Redux/GET_USER_COMPANY";
export const GET_USER_COMPANY_SUCCESS = "KAGO/Redux/GET_USER_COMPANY_SUCCESS";
export const GET_USER_COMPANY_FAIL = "KAGO/Redux/GET_USER_COMPANY_FAIL";

export const UPDATE_COMPANY = "KAGO/Redux/UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "KAGO/Redux/UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "KAGO/Redux/UPDATE_COMPANY_FAIL";

export const PASSWORD_RESET_USER = "KAGO/Redux/PASSWORD_RESET_USER";
export const PASSWORD_RESET_USER_SUCCESS =
  "KAGO/Redux/PASSWORD_RESET_USER_SUCCESS";
export const PASSWORD_RESET_USER_FAIL = "KAGO/Redux/PASSWORD_RESET_USER_FAIL";

//TRIP

export const CREATE_TRIP = "KAGO/Redux/CREATE_TRIP";
export const CREATE_TRIP_SUCCESS = "KAGO/Redux/CREATE_TRIP_SUCCESS";
export const CREATE_TRIP_FAIL = "KAGO/Redux/CREATE_TRIP_FAIL";

export const PING_TRIP = "KAGO/Redux/PING_TRIP";
export const PING_TRIP_SUCCESS = "KAGO/Redux/PING_TRIP_SUCCESS";
export const PING_TRIP_FAIL = "KAGO/Redux/PING_TRIP_FAIL";

export const GET_TRIP = "KAGO/Redux/GET_TRIP";
export const GET_TRIP_SUCCESS = "KAGO/Redux/GET_TRIP_SUCCESS";
export const GET_TRIP_FAIL = "KAGO/Redux/GET_TRIP_FAIL";

export const GET_PAST_TRIPS = "KAGO/Redux/GET_PAST_TRIPS";
export const GET_PAST_TRIPS_SUCCESS = "KAGO/Redux/GET_PAST_TRIPS_SUCCESS";
export const GET_PAST_TRIPS_FAIL = "KAGO/Redux/GET_PAST_TRIPS_FAIL";

export const GET_UPCOMING_TRIPS = "KAGO/Redux/GET_UPCOMING_TRIPS";
export const GET_UPCOMING_TRIPS_SUCCESS =
  "KAGO/Redux/GET_UPCOMING_TRIPS_SUCCESS";
export const GET_UPCOMING_TRIPS_FAIL = "KAGO/Redux/GET_UPCOMING_TRIPS_FAIL";

export const GET_CURRENTTRIP = "kago-mobile-platform/redux/GET_CURRENTTRIP";
export const GET_CURRENTTRIP_SUCCESS =
  "kago-mobile-platform/redux/GET_CURRENTTRIP_SUCCESS";
export const GET_CURRENTTRIP_FAIL =
  "kago-mobile-platform/redux/GET_CURRENTTRIP_FAIL";

export const GET_TRIP_TOKEN = "KAGO/Redux/GET_TRIP_TOKEN";
export const GET_TRIP_TOKEN_SUCCESS = "KAGO/Redux/GET_TRIP_TOKEN_SUCCESS";
export const GET_TRIP_TOKEN_FAIL = "KAGO/Redux/GET_TRIP_TOKEN_FAIL";

export const REPORT_TRIP = "KAGO/Redux/REPORT_TRIP";
export const REPORT_TRIP_SUCCESS = "KAGO/Redux/REPORT_TRIP_SUCCESS";
export const REPORT_TRIP_FAIL = "KAGO/Redux/REPORT_TRIP_FAIL";

export const REQUEST_TRIP = "KAGO/Redux/REQUEST_TRIP";
export const REQUEST_TRIP_SUCCESS = "KAGO/Redux/REQUEST_TRIP_SUCCESS";
export const REQUEST_TRIP_FAIL = "KAGO/Redux/REQUEST_TRIP_FAIL";

export const CANCEL_TRIP = "KAGO/Redux/CANCEL_TRIP";
export const CANCEL_TRIP_SUCCESS = "KAGO/Redux/CANCEL_TRIP_SUCCESS";
export const CANCEL_TRIP_FAIL = "KAGO/Redux/CANCEL_TRIP_FAIL";

export const CANCEL_CURRENT_TRIP = "KAGO/Redux/CANCEL_CURRENT_TRIP";
export const CANCEL_CURRENT_TRIP_SUCCESS =
  "KAGO/Redux/CANCEL_CURRENT_TRIP_SUCCESS";
export const CANCEL_CURRENT_TRIP_FAIL = "KAGO/Redux/CANCEL_CURRENT_TRIP_FAIL";

export const GET_ALL_TRIP = "KAGO/Redux/GET_ALL_TRIP";
export const GET_ALL_TRIP_SUCCESS = "KAGO/Redux/GET_ALL_TRIP_SUCCESS";
export const GET_ALL_TRIP_FAIL = "KAGO/Redux/GET_ALL_TRIP_FAIL";

export const GET_ONGOING_TRIP = "KAGO/Redux/GET_ONGOING_TRIP";
export const GET_ONGOING_TRIP_SUCCESS = "KAGO/Redux/GET_ONGOING_TRIP_SUCCESS";
export const GET_ONGOING_TRIP_FAIL = "KAGO/Redux/GET_ONGOING_TRIP_FAIL";

export const GET_ACCEPTEDTRIP = "kago-driver-platform/Redux/GET_ACCEPTEDTRIP";
export const GET_ACCEPTEDTRIP_SUCCESS =
  "kago-driver-platform/Redux/GET_ACCEPTEDTRIP_SUCCESS";
export const GET_ACCEPTEDTRIP_FAIL =
  "kago-driver-platform/Redux/GET_ACCEPTEDTRIP_FAIL";

export const GET_AVAILABLE_TRIPS = "KAGO/Redux/GET_AVAILABLE_TRIPS";
export const GET_AVAILABLE_TRIPS_SUCCESS =
  "KAGO/Redux/GET_AVAILABLE_TRIPS_SUCCESS";
export const GET_AVAILABLE_TRIPS_FAILURE =
  "KAGO/Redux/GET_AVAILABLE_TRIPS_FAILURE";

export const UPDATE_TRIP_STATUS = "KAGO/Redux/UPDATE_TRIP_STATUS";
export const UPDATE_TRIP_STATUS_SUCCESS =
  "KAGO/Redux/UPDATE_TRIP_STATUS_SUCCESS";
export const UPDATE_TRIP_STATUS_FAILURE =
  "KAGO/Redux/UPDATE_TRIP_STATUS_FAILURE";

export const RATE_DRIVER = "KAGO/Redux/RATE_DRIVER";
export const RATE_DRIVER_SUCCESS = "KAGO/Redux/RATE_DRIVER_SUCCESS";
export const RATE_DRIVER_FAIL = "KAGO/Redux/RATE_DRIVER_FAIL";

export const ADD_DRIVER_TO_COMPANY = "KAGO/Redux/ADD_DRIVER_TO_COMPANY";
export const ADD_DRIVER_TO_COMPANY_SUCCESS =
  "KAGO/Redux/ADD_DRIVER_TO_COMPANY_SUCCESS";
export const ADD_DRIVER_TO_COMPANY_FAIL =
  "KAGO/Redux/ADD_DRIVER_TO_COMPANY_FAIL";

export const REMOVE_DRIVER = "KAGO/Redux/REMOVE_DRIVER";
export const REMOVE_DRIVER_SUCCESS = "KAGO/Redux/REMOVE_DRIVER_SUCCESS";
export const REMOVE_DRIVER_FAIL = "KAGO/Redux/REMOVE_DRIVER_FAIL";

export const RESEND_INVITE = "KAGO/Redux/RESEND_INVITE";
export const RESEND_INVITE_SUCCESS = "KAGO/Redux/RESEND_INVITE_SUCCESS";
export const RESEND_INVITE_FAIL = "KAGO/Redux/RESEND_INVITE_FAIL";

export const GET_COMPANY_DRIVERS = "KAGO/Redux/GET_COMPANY_DRIVERS";
export const GET_COMPANY_DRIVERS_SUCCESS =
  "KAGO/Redux/GET_COMPANY_DRIVERS_SUCCESS";
export const GET_COMPANY_DRIVERS_FAIL = "KAGO/Redux/GET_COMPANY_DRIVERS_FAIL";

export const GET_PENDING_DRIVERS = "KAGO/Redux/GET_PENDING_DRIVERS";
export const GET_PENDING_DRIVERS_SUCCESS =
  "KAGO/Redux/GET_PENDING_DRIVERS_SUCCESS";
export const GET_PENDING_DRIVERS_FAIL = "KAGO/Redux/GET_PENDING_DRIVERS_FAIL";

export const GET_COMPANY_AVAILABLE_DRIVERS =
  "KAGO/Redux/GET_COMPANY_AVAILABLE_DRIVERS";
export const GET_COMPANY_AVAILABLE_DRIVERS_SUCCESS =
  "KAGO/Redux/GET_COMPANY_AVAILABLE_DRIVERS_SUCCESS";
export const GET_COMPANY_AVAILABLE_DRIVERS_FAIL =
  "KAGO/Redux/GET_COMPANY_AVAILABLE_DRIVERS_FAIL";

export const GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE =
  "KAGO/Redux/GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE";
export const GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_SUCCESS =
  "KAGO/Redux/GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_SUCCESS";
export const GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_FAIL =
  "KAGO/Redux/GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_FAIL";

export const GET_COMPANY_DRIVER_TRIPS = "KAGO/Redux/GET_COMPANY_DRIVER_TRIPS";
export const GET_COMPANY_DRIVER_TRIPS_SUCCESS =
  "KAGO/Redux/GET_COMPANY_DRIVER_TRIPS_SUCCESS";
export const GET_COMPANY_DRIVER_TRIPS_FAIL =
  "KAGO/Redux/GET_COMPANY_DRIVER_TRIPS_FAIL";

export const GET_RATING = "kago-driver-platform/Redux/GET_RATING";
export const GET_RATING_SUCCESS =
  "kago-driver-platform/Redux/GET_RATING_SUCCESS";
export const GET_RATING_FAIL = "kago-driver-platform/Redux/GET_RATING_FAIL";

//Cost
export const CREATE_COST = "KAGO/Redux/CREATE_COST";
export const CREATE_COST_SUCCESS = "KAGO/Redux/CREATE_COST_SUCCESS";
export const CREATE_COST_FAIL = "KAGO/Redux/CREATE_COST_FAIL";

// Push notification
export const PUSH_NOTIFICATION_TOKEN_REGISTRATION =
  "KAGO/Redux/PUSH_NOTIFICATION_TOKEN_REGISTRATION";
export const PUSH_NOTIFICATION_TOKEN_REGISTRATION_SUCCESS =
  "KAGO/Redux/PUSH_NOTIFICATION_TOKEN_REGISTRATION_SUCCESS";
export const PUSH_NOTIFICATION_TOKEN_REGISTRATION_FAIL =
  "KAGO/Redux/PUSH_NOTIFICATION_TOKEN_REGISTRATION_FAIL";

export const GET_UPLOAD = "KAGO/Redux/GET_UPLOAD";
export const GET_UPLOAD_SUCCESS = "KAGO/Redux/GET_UPLOAD_SUCCESS";
export const GET_UPLOAD_FAIL = "KAGO/Redux/GET_UPLOAD_FAIL";

//others
export const SET_MY_LOCATION = "KAGO/Redux/SET_MY_LOCATION";

export const GET_STATUS = "KAGO/Redux/GET_STATUS";

export const REQUEST_NOW = "KAGO/Redux/REQUEST_NOW";

//Vehicles

export const CREATE_VEHICLE = "KAGO/Redux/CREATE_VEHICLE";
export const CREATE_VEHICLE_SUCCESS = "KAGO/Redux/CREATE_VEHICLE_SUCCESS";
export const CREATE_VEHICLE_FAIL = "KAGO/Redux/CREATE_VEHICLE_FAIL";

export const GET_VEHICLES = "KAGO/Redux/GET_VEHICLES";
export const GET_VEHICLES_SUCCESS = "KAGO/Redux/GET_VEHICLES_SUCCESS";
export const GET_VEHICLES_FAIL = "KAGO/Redux/GET_VEHICLES_FAIL";

export const GET_VEHICLE = "KAGO/Redux/GET_VEHICLE";
export const GET_VEHICLE_SUCCESS = "KAGO/Redux/GET_VEHICLE_SUCCESS";
export const GET_VEHICLE_FAIL = "KAGO/Redux/GET_VEHICLE_FAIL";

export const UPDATE_VEHICLE = "KAGO/Redux/UPDATE_VEHICLE";
export const UPDATE_VEHICLE_SUCCESS = "KAGO/Redux/UPDATE_VEHICLE_SUCCESS";
export const UPDATE_VEHICLE_FAIL = "KAGO/Redux/UPDATE_VEHICLE_FAIL";

export const DELETE_VEHICLE = "KAGO/Redux/DELETE_VEHICLE";
export const DELETE_VEHICLE_SUCCESS = "KAGO/Redux/DELETE_VEHICLE_SUCCESS";
export const DELETE_VEHICLE_FAIL = "KAGO/Redux/DELETE_VEHICLE_FAIL";

export const CHANGE_PASSWORD = "KAGO/Redux/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "KAGO/Redux/CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "KAGO/Redux/CHANGE_PASSWORD_FAIL";

//Company
export const CREATE_COMPANY = "KAGO/Redux/CREATE_COMPANY";
export const CREATE_COMPANY_SUCCESS = "KAGO/Redux/CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAIL = "KAGO/Redux/REATE_COMPANY_FAIL";

export const GET_CURRENT_COMPANY = "KAGO/Redux/GET_CURRENT_COMPANY";
export const GET_CURRENT_COMPANY_SUCCESS =
  "KAGO/Redux/GET_CURRENT_COMPANY_SUCCESS";
export const GET_CURRENT_COMPANY_FAIL = "KAGO/Redux/GET_CURRENT_COMPANY_FAIL";

export const ACCEPT_INVITATION = "KAGO/Redux/ACCEPT_INVITATION";
export const ACCEPT_INVITATION_SUCCESS = "KAGO/Redux/ACCEPT_INVITATION_SUCCESS";
export const ACCEPT_INVITATION_FAIL = "KAGO/Redux/ACCEPT_INVITATION_FAIL";

//Verification

export const VERIFY_EMAIL_PHONE = "KAGO/Redux/VERIFY_EMAIL_PHONE";
export const VERIFY_EMAIL_PHONE_SUCCESS =
  "KAGO/Redux/VERIFY_EMAIL_PHONE_SUCCESS";
export const VERIFY_EMAIL_PHONE_FAIL = "KAGO/Redux/VERIFY_EMAIL_PHONE_FAIL";

export const RESEND_VERIFY_CODE = "KAGO/Redux/RESEND_VERIFY_CODE";
export const RESEND_VERIFY_CODE_SUCCESS =
  "KAGO/Redux/RESEND_VERIFY_CODE_SUCCESS";
export const RESEND_VERIFY_CODE_FAIL = "KAGO/Redux/RESEND_VERIFY_CODE_FAIL";

export const GET_AVAILABLE_VEHICLES = "KAGO/Redux/GET_AVAILABLE_VEHICLES";
export const GET_AVAILABLE_VEHICLES_SUCCESS =
  "KAGO/Redux/GET_AVAILABLE_VEHICLES_SUCCESS";
export const GET_AVAILABLE_VEHICLES_FAIL =
  "KAGO/Redux/GET_AVAILABLE_VEHICLES_FAIL";

export const ASSIGN_VEHICLE = "KAGO/Redux/ASSIGN_VEHICLE";
export const ASSIGN_VEHICLE_SUCCESS = "KAGO/Redux/ASSIGN_VEHICLE";
export const ASSIGN_VEHICLE_FAIL = "KAGO/Redux/ASSIGN_VEHICLE";

//Trackers
export const GET_TRACKERS_HEALTH = "KAGO/Redux/GET_TRACKERS_HEALTH";
export const GET_TRACKERS_HEALTH_SUCCESS =
  "KAGO/Redux/GET_TRACKERS_HEALTH_SUCCESS";
export const GET_TRACKERS_HEALTH_FAIL = "KAGO/Redux/GET_TRACKERS_HEALTH_FAIL";

export const VERIFY_TRACKER = "KAGO/Redux/VERIFY_TRACKER";
export const VERIFY_TRACKER_SUCCESS = "KAGO/Redux/VERIFY_TRACKER_SUCCESS";
export const VERIFY_TRACKER_FAIL = "KAGO/Redux/VERIFY_TRACKER_FAIL";

//FETCH TERMINALS
export const FETCH_TERMINALS = "KAGO/Redux/FETCH_TERMINALS";
export const FETCH_TERMINALS_SUCCESS = "KAGO/Redux/FETCH_TERMINALS_SUCCESS";
export const FETCH_TERMINALS_FAIL = "KAGO/Redux/FETCH_TERMINALS_FAIL";

//TRIP BID
export const CREATE_BID = "CREATE_BID";
export const CREATE_BID_SUCCESS = "CREATE_BID_SUCCESS";
export const CREATE_BID_FAIL = "CREATE_BID_FAIL";

//ACCEPT BID
export const BID_DECISION = "BID_DECISION";
export const BID_DECISION_SUCCESS = "BID_DECISION_SUCCESS";
export const BID_DECISION_FAIL = "BID_DECISION_FAIL";

//FETCH BIDS
export const FETCH_BIDS = "FETCH_BIDS";
export const FETCH_BIDS_SUCCESS = "FETCH_BIDS_SUCCESS";
export const FETCH_BIDS_FAIL = "FETCH_BIDS_FAIL";

//FETCH METRICS
export const FETCH_METRICS = "FETCH_METRICS";
export const FETCH_METRICS_SUCCESS = "FETCH_METRICS_SUCCESS";
export const FETCH_METRICS_FAIL = "FETCH_METRICS_FAIL";

//CREATE TRACKER
export const CREATE_TRACKER = "CREATE_TRACKER";
export const CREATE_TRACKER_SUCCESS = "CREATE_TRACKER_SUCCESS";
export const CREATE_TRACKER_FAIL = "CREATE_TRACKER_FAIL";

export function requestNow() {
  return {
    type: REQUEST_NOW,
    payload: {},
  };
}

export function testGet(id) {
  return {
    types: [GET_TEST, GET_TEST_SUCCESS, GET_TEST_FAIL],
    payload: {
      request: {
        method: "GET",
        baseURL: "https://stackoverflow.com",
        url: "/trips/" + id,
      },
    },
  };
}

export function getMe() {
  return {
    types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/users/me`,
      },
    },
  };
}

export function verifyUser(code) {
  return {
    types: [
      VERIFY_EMAIL_PHONE,
      VERIFY_EMAIL_PHONE_SUCCESS,
      VERIFY_EMAIL_PHONE_FAIL,
    ],
    payload: {
      request: {
        method: "POST",
        url: `/users/verify_email_or_phone`,
        data: {
          verification_code: code,
        },
      },
    },
  };
}

export function resendVerificationCode() {
  return {
    types: [
      RESEND_VERIFY_CODE,
      RESEND_VERIFY_CODE_SUCCESS,
      RESEND_VERIFY_CODE_FAIL,
    ],
    payload: {
      request: {
        method: "GET",
        url: `/users/verify_email_or_phone/resend_code`,
      },
    },
  };
}

export function getUserWithId(id) {
  return {
    types: [
      GET_USER_WITH_ID,
      GET_USER_WITH_ID_SUCCESS,
      GET_USER_WITH_ID_FAILURE,
    ],
    payload: {
      request: {
        method: "GET",
        url: `/users/${id}`,
      },
    },
  };
}

export function getRating(tripid) {
  return {
    types: [GET_RATING, GET_RATING_SUCCESS, GET_RATING_FAIL],
    payload: {
      request: {
        method: "GET",
        url: "/trips/" + tripid + "/ratings",
      },
    },
  };
}

export function getAcceptedTrips() {
  return {
    types: [GET_ACCEPTEDTRIP, GET_ACCEPTEDTRIP_SUCCESS, GET_ACCEPTEDTRIP_FAIL],
    payload: {
      request: {
        method: "GET",
        url: "/users/me/trips?sort=start_time&state=accepted",
      },
    },
  };
}

export function getCurrentTripwithId(id) {
  return {
    types: [GET_CURRENTTRIP, GET_CURRENTTRIP_SUCCESS, GET_CURRENTTRIP_FAIL],
    payload: {
      request: {
        method: "GET",
        url: "/trips/" + id,
      },
    },
  };
}

export function setDriverLocation(location) {
  return {
    type: SET_DRIVER_LOCATION,
    payload: {
      data: location,
    },
  };
}

export function setMyLocation(location) {
  return {
    type: SET_MY_LOCATION,
    payload: {
      data: location,
    },
  };
}

export function setCurrentTrip(trip) {
  return {
    type: SET_CURRENT_TRIP,
    payload: {
      data: trip,
    },
  };
}

export function setTripDetail(trip) {
  return {
    type: SET_TRIP_DETAIL,
    payload: {
      data: trip,
    },
  };
}

export function getAvailableTrips({ coordinates, bid_only }) {
  let url = `/trips/pending?populate=tdo cost_id&sort=created_at`;

  if (coordinates.lat)
    url += `&lat=${coordinates?.lat}&lng=${coordinates?.lng}`;
  if (bid_only) url += `&bid_only=${true}`;

  return {
    types: [
      GET_AVAILABLE_TRIPS,
      GET_AVAILABLE_TRIPS_SUCCESS,
      GET_AVAILABLE_TRIPS_FAILURE,
    ],
    payload: {
      request: {
        method: "GET",
        url,
      },
    },
  };
}

export function getCompanyAvailableDrivers(id) {
  return {
    types: [
      GET_COMPANY_AVAILABLE_DRIVERS,
      GET_COMPANY_AVAILABLE_DRIVERS_SUCCESS,
      GET_COMPANY_AVAILABLE_DRIVERS_FAIL,
    ],
    payload: {
      request: {
        method: "GET",
        url: `/trips/${id}/available_drivers`,
      },
    },
  };
}
export function getCompanyAvailableDriversByItemSize(data) {
  return {
    types: [
      GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE,
      GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_SUCCESS,
      GET_COMPANY_AVAILABLE_DRIVERS_BY_SIZE_FAIL,
    ],
    payload: {
      request: {
        method: "POST",
        url: `/trips/available_drivers`,
        data,
      },
    },
  };
}

export function getAvailableVehicles(id) {
  return {
    types: [
      GET_AVAILABLE_VEHICLES,
      GET_AVAILABLE_VEHICLES_SUCCESS,
      GET_AVAILABLE_VEHICLES_FAIL,
    ],
    payload: {
      request: {
        method: "GET",
        url: `/companies/${id}/available_vehicles`,
      },
    },
  };
}

export function updateTripStatus({ driver_id, id, status }) {
  return {
    types: [
      UPDATE_TRIP_STATUS,
      UPDATE_TRIP_STATUS_SUCCESS,
      UPDATE_TRIP_STATUS_FAILURE,
    ],
    payload: {
      request: {
        method: "POST",
        url: `/trips/${id}/update_status`,
        data: {
          status,
          driver_id,
        },
      },
    },
  };
}

export function getTripToken(tripId) {
  return {
    types: [GET_TRIP_TOKEN, GET_TRIP_TOKEN_SUCCESS, GET_TRIP_TOKEN_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/trips/${tripId}/tracking_permission`,
        data: {
          trip_id: tripId,
        },
      },
    },
  };
}

export function getCompanyDriverTrips(companyId, driverId) {
  return {
    types: [
      GET_COMPANY_DRIVER_TRIPS,
      GET_COMPANY_DRIVER_TRIPS_SUCCESS,
      GET_COMPANY_DRIVER_TRIPS_FAIL,
    ],
    payload: {
      request: {
        method: "GET",
        url: `/companies/${companyId}/drivers/${driverId}/trips`,
      },
    },
  };
}

// export function pingTrip(longitude,latitude) {
//     return {
//         types: [PING_TRIP,PING_TRIP_SUCCESS,PING_TRIP_FAIL],
//         payload: {
//             request: {
//                 method: 'POST',
//                 baseURL: config.SOCKET_URL,
//                 url: '',
//                 data:{
//                     location:[longitude,latitude]
//                 }
//             }
//         }
//     };
// }

export function rateTrip(trip, rating) {
  return {
    types: [RATE_DRIVER, RATE_DRIVER_SUCCESS, RATE_DRIVER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "ratings",
        data: {
          trip: trip._id,
          rating: rating,
        },
      },
    },
  };
}

export function requestPasswordResetEmail(email) {
  return {
    types: [
      PASSWORD_RESET_EMAIL,
      PASSWORD_RESET_EMAIL_SUCCESS,
      PASSWORD_RESET_EMAIL_FAIL,
    ],
    payload: {
      request: {
        url: `/users/forgot_password`,
        method: "POST",
        data: {
          email: email,
        },
      },
    },
  };
}

export function resetPassword(password, token) {
  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
    payload: {
      request: {
        url: `/users/password_reset`,
        method: "POST",
        data: {
          password: password,
          token: token,
        },
      },
    },
  };
}

export function signUp(user) {
  return {
    types: [SIGNUP_USER, SIGNUP_USER_SUCCESS, SIGNUP_USER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/users`,
        data: user,
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function signUpUserForCompany(user, companyId) {
  return {
    types: [
      SIGNUP_USER_FOR_COMPANY,
      SIGNUP_USER_FOR_COMPANY_SUCCESS,
      SIGNUP_USER_FOR_COMPANY_FAIL,
    ],
    payload: {
      request: {
        method: "POST",
        url: `/companies/${companyId}/drivers/add`,
        data: user,
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function loginUser(email, password) {
  return {
    types: [LOGIN_USER, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/oauth`,
        data: {
          email: email,
          password: password,
        },
      },
    },
  };
}

export function logOutUser() {
  return {
    types: [LOGOUT_USER, LOGOUT_USER_SUCCESS, LOGOUT_USER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "/users/logout",
        data: {},
      },
    },
  };
}

export function updateUser(user) {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL],
    payload: {
      request: {
        method: "PUT",
        url: `/users/me`,
        data: user,
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function updateCompany(company) {
  return {
    types: [UPDATE_COMPANY, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAIL],
    payload: {
      request: {
        method: "PUT",
        url: `/companies/${company._id}`,
        data: company,
      },
    },
  };
}

export function addDriverToCompany(id, email) {
  return {
    types: [
      ADD_DRIVER_TO_COMPANY,
      ADD_DRIVER_TO_COMPANY_SUCCESS,
      ADD_DRIVER_TO_COMPANY_FAIL,
    ],
    payload: {
      request: {
        method: "POST",
        url: `/companies/${id}/drivers`,
        data: {
          email: email,
        },
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function removeDriver(data, companyId) {
  return {
    types: [REMOVE_DRIVER, REMOVE_DRIVER_SUCCESS, REMOVE_DRIVER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/companies/${companyId}/drivers/remove`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function resendInvite(id, email) {
  return {
    types: [RESEND_INVITE, RESEND_INVITE_SUCCESS, RESEND_INVITE_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/companies/${id}/drivers/resend_invite`,
        data: {
          email,
        },
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function getCompanyDrivers(id, page, limit) {
  let url = `/companies/${id}/drivers?sort=created_at`;
  if (page !== undefined && limit) url += `&page=${page}&limit=${limit}`;

  return {
    types: [
      GET_COMPANY_DRIVERS,
      GET_COMPANY_DRIVERS_SUCCESS,
      GET_COMPANY_DRIVERS_FAIL,
    ],
    payload: {
      request: {
        method: "GET",
        url,
      },
    },
  };
}

export function getPendingDrivers(id) {
  return {
    types: [
      GET_PENDING_DRIVERS,
      GET_PENDING_DRIVERS_SUCCESS,
      GET_PENDING_DRIVERS_FAIL,
    ],
    payload: {
      request: {
        method: "GET",
        url: `/companies/${id}/pending_invitations`,
      },
    },
  };
}

export function getCompanies() {
  return {
    types: [GET_USER_COMPANY, GET_USER_COMPANY_SUCCESS, GET_USER_COMPANY_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/users/me/companies`,
        data: {},
      },
    },
  };
}

export function getCompany() {
  return {
    types: [
      GET_CURRENT_COMPANY,
      GET_CURRENT_COMPANY_SUCCESS,
      GET_CURRENT_COMPANY_FAIL,
    ],
    payload: {
      request: {
        method: "GET",
        url: `/users/me/companies`,
        data: {},
      },
    },
  };
}

export function getVehicles({ page, limit }) {
  let url = `/users/me/vehicles?sort=created_at`;
  if (page !== undefined && limit) url += `&page=${page}&limit=${limit}`;

  return {
    types: [GET_VEHICLES, GET_VEHICLES_SUCCESS, GET_VEHICLES_FAIL],
    payload: {
      request: {
        method: "GET",
        url,
      },
    },
  };
}

export function getVehicle(id) {
  return {
    types: [GET_VEHICLE, GET_VEHICLE_SUCCESS, GET_VEHICLE_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/vehicles/${id}`,
        data: {},
      },
    },
  };
}

export function assignVehicle(company_id, driver_id, vehicle_id) {
  return {
    types: [ASSIGN_VEHICLE, ASSIGN_VEHICLE_SUCCESS, ASSIGN_VEHICLE_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/companies/${company_id}/drivers/${driver_id}/assign_vehicle`,
        data: {
          vehicle_id,
        },
      },
    },
  };
}

export function updateVehicle(data) {
  return {
    types: [UPDATE_VEHICLE, UPDATE_VEHICLE_SUCCESS, UPDATE_VEHICLE_FAIL],
    payload: {
      request: {
        method: "PUT",
        url: `/vehicles/${data._id}`,
        data: data,
      },
    },
  };
}

export function deleteVehicle(id) {
  return {
    types: [DELETE_VEHICLE, DELETE_VEHICLE_SUCCESS, DELETE_VEHICLE_FAIL],
    payload: {
      request: {
        method: "DELETE",
        url: `/vehicles/${id}`,
      },
    },
  };
}

export function forgotPassword(email) {
  return {
    types: [
      PASSWORD_RESET_USER,
      PASSWORD_RESET_USER_SUCCESS,
      PASSWORD_RESET_USER_FAIL,
    ],
    payload: {
      request: {
        method: "POST",
        url: `/users/forgot_password`,
        data: {
          email: email,
        },
      },
    },
  };
}

// export function pushTokenRegistration (token) {
//     return {
//         types: [PUSH_NOTIFICATION_TOKEN_REGISTRATION,PUSH_NOTIFICATION_TOKEN_REGISTRATION_SUCCESS,PUSH_NOTIFICATION_TOKEN_REGISTRATION_FAIL],
//         payload: {
//             request: {
//                 method: 'POST',
//                 url: 'devices',
//                 data: {
//                     notification_token: token,
//                     device_type: Platform.OS
//                 }
//             }
//         }
//     };
// }

//TRIPS

export function createTrip(data) {
  return {
    types: [CREATE_TRIP, CREATE_TRIP_SUCCESS, CREATE_TRIP_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "trips",
        data: data,
      },
    },
  };
}

export function getOngoingTrips() {
  return {
    types: [GET_ONGOING_TRIP, GET_ONGOING_TRIP_SUCCESS, GET_ONGOING_TRIP_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/users/me/trips/ongoing`,
      },
    },
  };
}

export function getTrips() {
  return {
    types: [GET_ALL_TRIP, GET_ALL_TRIP_SUCCESS, GET_ALL_TRIP_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/users/me/trips`,
        data: {},
      },
    },
  };
}

export function getTrip(id) {
  return {
    types: [GET_TRIP, GET_TRIP_SUCCESS, GET_TRIP_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/trips/${id}`,
        data: {},
      },
    },
  };
}

export function getPastTrips(page = 0) {
  return {
    types: [GET_PAST_TRIPS, GET_PAST_TRIPS_SUCCESS, GET_PAST_TRIPS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/users/me/trips?populate=driver vehicle cost_id&sort=start_time&state=${TRIP_STATE.CANCELLED},${TRIP_STATE.FINISHED}&page= ${page}`,
      },
      data: {
        page: page,
      },
    },
  };
}

export function getUpcomingTrips(page = 0) {
  return {
    types: [
      GET_UPCOMING_TRIPS,
      GET_UPCOMING_TRIPS_SUCCESS,
      GET_UPCOMING_TRIPS_FAIL,
    ],
    payload: {
      request: {
        method: "GET",
        url: `/users/me/trips?populate=driver vehicle cost_id&sort=created_at&state=${TRIP_STATE.ACCEPTED},${TRIP_STATE.PENDING},${TRIP_STATE.PAYMENT_PENDING}&page=${page}`,
      },
      data: {
        page: page,
      },
    },
  };
}

export function requestTrip(trip) {
  return {
    types: [REQUEST_TRIP, REQUEST_TRIP_SUCCESS, REQUEST_TRIP_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "/trips",
        data: trip,
      },
    },
  };
}

export function cancelTrip(tripid) {
  return {
    types: [CANCEL_TRIP, CANCEL_TRIP_SUCCESS, CANCEL_TRIP_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "/trips/" + tripid + "/cancel",
        data: {
          id: tripid,
        },
      },
    },
  };
}

export function cancelCurrentTrip(id) {
  return {
    types: [
      CANCEL_CURRENT_TRIP,
      CANCEL_CURRENT_TRIP_SUCCESS,
      CANCEL_CURRENT_TRIP_FAIL,
    ],
    payload: {
      request: {
        url: "/trips/" + id,
      },
    },
  };
}

export function reportTrip(tripid, message) {
  return {
    types: [REPORT_TRIP, REQUEST_TRIP_SUCCESS, REQUEST_TRIP_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "/reports",
        data: {
          message: message,
          trip_id: tripid,
        },
      },
    },
  };
}

export function createCost(data) {
  return {
    types: [CREATE_COST, CREATE_COST_SUCCESS, CREATE_COST_FAIL],
    payload: {
      request: {
        method: "POST",
        url: "/costs",
        data,
      },
    },
  };
}

export function getUploadUrl(contentType) {
  return {
    types: [GET_UPLOAD, GET_UPLOAD_SUCCESS, GET_UPLOAD_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/upload_url`,
        data: {
          content_type: contentType,
        },
      },
    },
  };
}

export function acceptInvitation(id, token) {
  return {
    types: [
      ACCEPT_INVITATION,
      ACCEPT_INVITATION_SUCCESS,
      ACCEPT_INVITATION_FAIL,
    ],
    payload: {
      request: {
        method: "POST",
        url: `/companies/${id}/accept`,
        headers: {
          Authorization: token,
        },
      },
    },
  };
}

export function createVehicle(vehicle) {
  return {
    types: [CREATE_VEHICLE, CREATE_VEHICLE_SUCCESS, CREATE_VEHICLE_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/vehicles`,
        data: vehicle,
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function createCompany(company) {
  return {
    types: [CREATE_COMPANY, CREATE_COMPANY_SUCCESS, CREATE_COMPANY_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/companies`,
        data: company,
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
}

export function changePassword(oldPassword, newPassword) {
  return {
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `/users/me/change_password`,
        data: { oldPassword, newPassword },
      },
      // headers: {
      //     'Content-Type': 'application/json',
      // },
    },
  };
}

export function getTrackersHealth(imeis) {
  return {
    types: [
      GET_TRACKERS_HEALTH,
      GET_TRACKERS_HEALTH_SUCCESS,
      GET_TRACKERS_HEALTH_FAIL,
    ],
    payload: {
      request: {
        method: "POST",
        baseURL: envConfig.REACT_APP_TRIP_SERVER,
        url: "/trackers/health",
        data: { imeis },
        headers: {
          "x-gotruck-signature": envConfig.REACT_APP_GOTRUCK_SIGNATURE,
        },
      },
    },
  };
}

export function verifyTracker(imei) {
  return {
    types: [VERIFY_TRACKER, VERIFY_TRACKER_SUCCESS, VERIFY_TRACKER_FAIL],
    payload: {
      request: {
        method: "POST",
        baseURL: envConfig.REACT_APP_TRIP_SERVER,
        url: "/trackers/verify",
        data: { imei },
        headers: {
          "x-gotruck-signature": envConfig.REACT_APP_GOTRUCK_SIGNATURE,
        },
      },
    },
  };
}

export function fetchTerminals() {
  return {
    types: [FETCH_TERMINALS, FETCH_TERMINALS_SUCCESS, FETCH_TERMINALS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `/terminals`,
      },
    },
  };
}

export function createBid({ id, bid_price, driver_id }) {
  return {
    types: [CREATE_BID, CREATE_BID_SUCCESS, CREATE_BID_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `trips/${id}/bid`,
        data: { bid_price, driver_id },
      },
    },
  };
}

export function makeBidDecision({ tripId, bidId, decision, driver_id }) {
  return {
    types: [BID_DECISION, BID_DECISION_SUCCESS, BID_DECISION_FAIL],
    payload: {
      request: {
        method: "PUT",
        url: `trips/${tripId}/bid/${bidId}`,
        data: { decision, driver_id },
      },
    },
  };
}

export function getTripBids(id) {
  return {
    types: [FETCH_BIDS, FETCH_BIDS_SUCCESS, FETCH_BIDS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `trips/${id}/bid?populate=bid_creator company_id driver_id trip&sort=created_at`,
      },
    },
  };
}

export function getMetrics(id) {
  return {
    types: [FETCH_METRICS, FETCH_METRICS_SUCCESS, FETCH_METRICS_FAIL],
    payload: {
      request: {
        method: "GET",
        url: `companies/${id}/metrics`,
      },
    },
  };
}

export function createTracker(body) {
  return {
    types: [CREATE_TRACKER, CREATE_TRACKER_SUCCESS, CREATE_TRACKER_FAIL],
    payload: {
      request: {
        method: "POST",
        url: `trackers`,
        data: body,
      },
    },
  };
}
